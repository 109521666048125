<template>
  <div>
    <v-row align="center">
      <v-col>
        <h3 class="ma-1">Venda: #{{ item.number }}</h3>
        <v-chip small :color="resolveOrderBilledVariant(item.billed)"
          :class="`${resolveOrderBilledVariant(item.billed)}--text`" class="font-weight-semibold text-capitalize">
          <div v-if="item.billed === 1">Faturado</div>
          <div v-if="item.billed === 0">Não Faturado</div>
        </v-chip>
        <v-chip small class="font-weight-semibold text-capitalize" :color="resolveStatusVariant(item.status)">
          <div v-if="item.status === 'draft'">Rascunho</div>
          <div v-if="item.status === 'pendding'">Pendente</div>
          <div v-if="item.status === 'order'">Processado</div>
          <div v-if="item.status === 'cancelled'">Cancelado</div>
        </v-chip>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="9">
        <v-card class="px-2">
          <v-card-text>
            <v-card-title>
              <h4>Produtos</h4>
            </v-card-title>
            <v-simple-table class="custom-table-height">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">SKU</th>
                    <th class="text-left">Produto</th>
                    <th class="text-left">Quantidade</th>
                    <th class="text-left">Preço</th>
                    <th class="text-left">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="product in item.order_product" :key="product.id">
                    <td>{{ product.product.sku }}</td>
                    <td>{{ product.name }} </br>
                      {{ product.product.product_platform_id }}
                    </td>
                    <td>{{ product.quantity }}</td>
                    <td>{{ (product.value / 100) | moneyBr }}</td>
                    <td>
                      {{ ((product.value * product.quantity) / 100) | moneyBr }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3" class="d-flex justify-end">
        <v-card class="px-4">
          <v-row class="mt-4 d-flex justify-space-between">
            <v-col cols="3" class="text-center">
              <v-avatar size="46">
                <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="Avatar" />
              </v-avatar>
            </v-col>
            <v-col cols="9">
              <div>
                <strong>{{ item.order_customer.name | toCamelCase }}</strong>
              </div>
              <small>CPF: {{ item.order_customer.cpf | cpf }}</small>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row class="mt-1">
            <v-col cols="12">
              <div>
                <strong>Informações</strong>
              </div>
              <small>{{ item.order_customer.email }}</small> <br />
              <small>{{ item.order_customer.phone | phone }}</small>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="mt-1">
            <v-col cols="12">
              <div class="mb-1">
                <strong>Endereço</strong>
              </div>
              <small><strong>Cep:</strong> {{ item.order_customer.main_address.cep | cep }}</small> <br />
              <small><strong>Rua:</strong> {{ item.order_customer.main_address.street | toCamelCase }}</small> <br />
              <small><strong>Numero:</strong> {{ item.order_customer.main_address.number }}</small> <br />
              <small><strong>Bairro:</strong> {{ item.order_customer.main_address.neighborhood | toCamelCase }}</small>
              <br />
              <small><strong>Cidade:</strong> {{ item.order_customer.main_address.city | toCamelCase }} - {{
                item.order_customer.main_address.state }}</small>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9">
        <v-card class="px-2">
          <v-card-text>
            <v-card-title>
              <h4>Notas Fiscais</h4>
            </v-card-title>
            <v-data-table v-if="nfse.length > 0" :headers="headers" :items="nfse" item-value="numero" class="elevation-1">
              <template v-slot:item.link="{ item }">
                <v-btn :href="item.link" target="_blank" text>
                  Ver Nota
                </v-btn>
              </template>
              <template v-slot:item.valor="{ item }">
                {{ item.value | moneyBr}}
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip :color="resolveStatusVariant(item.status)" dark>
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text>
          <v-data-table v-if="nfeProducts.length > 0" :headers="headers" :items="nfeProducts" item-value="numero" class="elevation-1">
              <template v-slot:item.link="{ item }">
                <v-btn :href="item.link" target="_blank" text>
                  Ver Nota
                </v-btn>
              </template>
              <template v-slot:item.valor="{ item }">
                {{ item.value | moneyBr}}
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip :color="resolveStatusVariant(item.status)" dark>
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { moneyBr } from "@/util/Filters";
import orderMixin from "@/util/mixins/orderMixin";
import tableMixin from "@/util/mixins/tableMixin";

export default {
  mixins: [orderMixin, tableMixin],
  data() {
    return {
      paramValue: "",
      item: {},
      nfse: [],
      nfeProducts: [],

      headers: [
        { text: 'Número da Nota', value: 'number' },
        { text: 'Chave da Nota', value: 'chave' },
        { text: 'Série', value: 'serie' },
        { text: 'Link da Nota', value: 'link' },
        { text: 'Valor', value: 'valor' },
        { text: 'Status', value: 'status' },
      ],
    };
  },
  created() {
    this.paramValue = this.$route.params.id;
  },
  mounted() {
    this.getOrders();
  },
  watch: {
    $route() {
      this.paramValue = this.$route.params.id;
    },
  },
  methods: {
    getOrders() {
      this.$http
        .$get("/order/" + this.paramValue)
        .then((response) => {
          this.item = response.data;
          this.nfse = response.data.nfse;
          this.nfeProducts = response.data.nfe_product55;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.custom-table-height {
  height: 250px;
}
</style>
